import React from 'react';
import { TReportTypeCode, TGSTPropertyLabels } from '@@config/report';

type TLabGrownBannerProps = {
    readonly reportTypeCode: TReportTypeCode;
    readonly i18n: TGSTPropertyLabels;
};

const LabGrownBanner: React.FC<TLabGrownBannerProps> = ({ reportTypeCode, i18n }) => {
    let bannerTitle: string | undefined = 'LABORATORY-GROWN DIAMOND REPORT';
    switch (reportTypeCode) {
        case 'LGDG':
            bannerTitle = i18n?.['gia_www_i18n_reportchecktitlelgdg'];
            break;
        case 'LGCD':
            bannerTitle = i18n?.['gia_www_i18n_reportchecktitlelgcd'];
            break;
        case 'LGCI':
            bannerTitle = i18n?.['gia_www_i18n_reportchecktitlelgci'];
            break;
        case 'LGDOSS':
            bannerTitle = i18n?.['gia_www_i18n_reportchecktitlelgdoss'];
            break;
        default:
            bannerTitle = 'LABORATORY-GROWN DIAMOND REPORT';
            break;
    }

    return (
        <section className='w-100 mt-2' id='lgdr-banner'>
            <img className='img-fluid' src='https://www.gia.edu/img/lgdr-top-banner-large.png' />
            {bannerTitle && <h1 className='w-100 font-weight-bold text-left p-0 my-3'>{bannerTitle}</h1>}
        </section>
    );
};

export { TLabGrownBannerProps };
export default LabGrownBanner;
