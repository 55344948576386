import React from 'react';
import { TTableItemData } from '@@components/GradingReport/GradingReport';
import InscriptionImage from '@@components/InscriptionImage/InscriptionImage';
import { TInscriptionGraphic } from '@@config/report';

type TInscriptionGraphicsMap = {
    [description: string]: string;
};

type TInscriptionRowProps = {
    tableItemKey: string;
    tableItemData: TTableItemData;
    graphics?: TInscriptionGraphic[];
};

const InscriptionRow: React.FC<TInscriptionRowProps> = ({
    tableItemKey,
    tableItemData,
    graphics = [],
}): React.JSX.Element => {
    const { VALUE: inscription } = tableItemData;

    // create a lookup map for graphics
    const graphicsMap = graphics.reduce<TInscriptionGraphicsMap>((map, graphic) => {
        map[graphic.description.toLowerCase()] = graphic.image;
        return map;
    }, {});

    /**
     * Validates if a given URL string points to an inscription graphic image.
     * The URL must use HTTP/HTTPS protocol and contain an imgType query param.
     * with value 'INSCRIPTIONGRAPHIC'.
     *
     * @param source - The URL string to validate
     * @returns {boolean}
     */
    const _isValidInscriptionGraphicUrl = (source: string): boolean => {
        try {
            const url = new URL(source);
            const params = url.searchParams;

            return (
                (url.protocol === 'http:' || url.protocol === 'https:') &&
                params.get('imgType') === 'INSCRIPTIONGRAPHIC'
            );
        } catch {
            return false;
        }
    };

    /**
     * Renders inscription text with embedded graphic images.
     * Processes text containing graphic tags (e.g., [SWORD]) by converting them
     * to <img> elements if a valid image URL exists in graphicsMap.
     * Regular text is wrapped in <span> elements.
     *
     * @returns {React.ReactNode[]} Array of React elements (spans and images)
     */
    const renderInscription = (): React.ReactNode[] => {
        const regex = /(?<graphic>\[[A-Z0-9\s®™©'"!?.,\-—–&]+])|(?<text>[^[]{1,500})/gi;
        const elements: React.ReactNode[] = [];
        let match;

        // collect all matches using exec()
        while ((match = regex.exec(inscription)) !== null) {
            const { graphic = '', text = '' } = match?.groups ?? {};
            const index = elements.length;
            // example of text: 'I love you '
            // example of graphic: [INFINITY SYMBOL]

            if (graphic) {
                const image = graphicsMap[graphic.toLowerCase()] ?? '';
                elements.push(
                    _isValidInscriptionGraphicUrl(image) ? (
                        <InscriptionImage key={`${tableItemKey}-${index}`} imageSource={image} altText={graphic} />
                    ) : (
                        graphic
                    )
                );
            }

            if (text) {
                elements.push(<span key={`${tableItemKey}-text-${index}`}>{text}</span>);
            }
        }

        return elements;
    };

    return (
        <tr className='inscription-row' key={tableItemKey}>
            <td colSpan={2}>{renderInscription()}</td>
        </tr>
    );
};

export default InscriptionRow;
