import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import FeaturedImage, { TFeaturedImage } from '@@components/FeaturedImage/FeaturedImage';
import GridTable from '@@components/GridTable/GridTable';
import GridTableHeader from '@@components/GridTableHeader/GridTableHeader';
import { TGridTableRowData } from '@@components/GridTableRow/GridTableRow';
import SimpleSlider, { TSlide } from '@@components/SimpleSlider/SimpleSlider';
import { TJewelryReportData, TLabels } from '@@config/report';

type TJewelryGradingReportProps = {
    readonly data: TJewelryReportData;
    readonly labels: TLabels;
};

const JewelryGradingReport: React.FC<TJewelryGradingReportProps> = ({ data, labels }) => {
    const jewelryReportTitle: string = labels?.['JEWELRY_REPORT_TITLE']?.heading ?? 'Jewelry Report';
    /**
     * Jewelry Images (sometimes one, sometimes two)
     * - If there are two images, they are displayed within a Slider
     * - If there is only a top view image, it is displayed as a FeaturedImage
     */
    let slides: TSlide[] | undefined = undefined;
    if (data['JEWELRY_TOP_VIEW_IMAGE'] && data['JEWELRY_SIDE_VIEW_IMAGE']) {
        slides = [
            { image: { src: data['JEWELRY_TOP_VIEW_IMAGE'], alt: labels['JEWELRY_TOP_VIEW_IMAGE']?.label ?? '' } },
            { image: { src: data['JEWELRY_SIDE_VIEW_IMAGE'], alt: labels['JEWELRY_SIDE_VIEW_IMAGE']?.label ?? '' } },
        ];
    }

    const jewelryPrimaryImage: TFeaturedImage | undefined =
        data['JEWELRY_TOP_VIEW_IMAGE'] && !data['JEWELRY_SIDE_VIEW_IMAGE']
            ? {
                  src: data['JEWELRY_TOP_VIEW_IMAGE'],
                  alt: labels['JEWELRY_TOP_VIEW_IMAGE']?.label ?? '',
              }
            : undefined;

    // Jewelry Details >> <GridTable numCols={2} />
    const jewelryDetails = {
        title: labels['JEWELRY_DETAILS']?.heading ?? 'Jewelry Details',
        rows: ['METAL_TESTED_AS', 'ITEMS_WEIGHT', 'ENGRAVINGS', 'STAMPINGS']
            .filter(key => data[key as keyof TJewelryReportData])
            .map(key => ({
                label: labels[key]?.label ?? '',
                tooltip: labels[key]?.tooltip ?? '',
                value: data[key as keyof Omit<TJewelryReportData, 'GIA_REPORTS' | 'ADDITIONAL_REPORTS'>],
            })),
    };

    const giaReports = {
        title: labels['GIA_REPORTS']?.heading ?? 'GIA Report(s)',
        rows:
            data.GIA_REPORTS?.map(report =>
                [
                    'REPORT_NO',
                    'SHAPE',
                    'MEASUREMENTS',
                    'WEIGHT',
                    'COLOR_GRADE',
                    'CLARITY_GRADE',
                    'CUT_GRADE',
                    'INSCRIPTION',
                ]
                    .filter(key => key in report)
                    .map(key => ({
                        label: labels[key]?.label ?? '',
                        tooltip: labels[key]?.tooltip ?? '',
                        value: report[key] ?? '',
                    }))
            ) ?? [],
    };

    const continueReadingLabel: string = labels['CONTINUE_READING_REPORT']?.label ?? 'Continue Reading Full GIA Report';
    const hasGiaReports: boolean = giaReports.rows.length > 0;

    // Additional Reports (one-to-many) Array of >> <GridTable numCols={2} />
    let additionalReportsTablesTitle: string = labels['ADDITIONAL_REPORTS']?.heading ?? 'Additional Stone(s)';
    if (!hasGiaReports) {
        additionalReportsTablesTitle = labels['STONE_DETAILS']?.heading ?? 'Stone Detail(s)';
    }

    const additionalReports = {
        title: additionalReportsTablesTitle,
        rows:
            data.ADDITIONAL_REPORTS?.map(report =>
                [
                    'TOTAL_STONES',
                    'ESTIMATED_CLARITY_RANGE',
                    'ESTIMATED_COLOR_RANGE',
                    'ESTIMATED_TOTAL_CARAT_WEIGHT',
                    'ADDITIONAL_SHAPES',
                ]
                    .filter(key => key in report)
                    .map(key => ({
                        label: labels[key]?.label ?? '',
                        tooltip: labels[key]?.tooltip ?? '',
                        value: report[key] ?? '',
                    }))
            ) ?? [],
    };

    // Jewelry Description (Item/(s) Overall Description) >> <GridTable numCols={1} />
    const jewelryDescTableTitle: string = labels['JEWELRY_DESCRIPTION']?.label ?? 'Item(s) Overall Description';
    const jewelryDescDataRowContent: TGridTableRowData[] = (data['JEWELRY_DESCRIPTION'] ?? '')
        .split(/\.\s/)
        .filter(Boolean)
        .map((sentence: string) => ({
            label: '',
            tooltip: '',
            value: sentence,
        }));

    if (hasGiaReports) {
        return (
            <>
                <h1 className='h2 bg-none letter-spacing-normal pb-3'>{jewelryReportTitle}</h1>

                {slides && <SimpleSlider slides={slides} />}
                {jewelryPrimaryImage && <FeaturedImage src={jewelryPrimaryImage.src} alt={jewelryPrimaryImage.alt} />}

                <GridTableHeader title={jewelryDetails.title} />
                <GridTable rowsData={jewelryDetails.rows} numCols={2} />

                <Tabs defaultActiveKey='gem-reports' className='nav-tabs report-nav d-flex w-100 border-0'>
                    <Tab
                        eventKey='gem-reports'
                        className='py-4'
                        title={labels['GEM_REPORTS']?.label ?? 'Gem Report(s)'}
                    >
                        <GridTableHeader title={giaReports.title} />
                        {giaReports.rows.map((rowsData: TGridTableRowData[], index: number) => (
                            <GridTable
                                key={`grid-table-${index}`}
                                rowsData={rowsData}
                                numCols={2}
                                continueReadingLabel={continueReadingLabel}
                                tableType='gemstone'
                            />
                        ))}
                    </Tab>
                    <Tab
                        eventKey='jewelry-overview'
                        className='py-4'
                        title={labels['JEWELRY_OVERVIEW']?.label ?? 'Jewelry Overview'}
                    >
                        {additionalReports.rows.length > 0 && (
                            <>
                                <GridTableHeader title={additionalReports.title} />
                                {additionalReports.rows.map((rowsData: TGridTableRowData[], index: number) => (
                                    <GridTable key={`grid-table-${index}`} rowsData={rowsData} numCols={2} />
                                ))}
                            </>
                        )}
                        {jewelryDescDataRowContent.length > 0 && (
                            <>
                                <GridTableHeader title={jewelryDescTableTitle} />
                                <GridTable rowsData={jewelryDescDataRowContent} numCols={1} />
                            </>
                        )}
                    </Tab>
                </Tabs>
            </>
        );
    }

    return (
        <>
            <h1 className='h2 bg-none letter-spacing-normal pb-3'>{jewelryReportTitle}</h1>

            {slides && <SimpleSlider slides={slides} />}
            {jewelryPrimaryImage && <FeaturedImage src={jewelryPrimaryImage.src} alt={jewelryPrimaryImage.alt} />}

            <GridTableHeader title={jewelryDetails.title} />
            <GridTable rowsData={jewelryDetails.rows} numCols={2} />

            {additionalReports.rows.length > 0 && (
                <>
                    <GridTableHeader title={additionalReports.title} />
                    {additionalReports.rows.map((rowsData: TGridTableRowData[], index: number) => (
                        <GridTable key={`grid-table-${index}`} rowsData={rowsData} numCols={2} />
                    ))}
                </>
            )}

            {jewelryDescDataRowContent.length > 0 && (
                <>
                    <GridTableHeader title={jewelryDescTableTitle} />
                    <GridTable rowsData={jewelryDescDataRowContent} numCols={1} />
                </>
            )}
        </>
    );
};

export type { TJewelryGradingReportProps };
export default JewelryGradingReport;
