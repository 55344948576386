import React from 'react';
import { TGSTPropertyLabels, TReportTypeCode } from '@@config/report';

type TReportSupplementalProps = {
    readonly reportTypeCode: TReportTypeCode;
    readonly reportNumber: string | number;
    readonly primaryCTA?: URL | undefined /* Default: Report PDF URL */;
    readonly reportDAC: string /* Report Access Card URL */;
    readonly reportDTL: string /* Diamond Type Letter (PDF) URL */;
    readonly reportDTLP: string /* Diamond Type Letter with Photo (PDF) URL */;
    readonly reportDTTL: string /* Diamond Color Treatment Letter (PDF) URL */;
    readonly i18n: TGSTPropertyLabels;
};

const ReportSupplemental: React.FC<TReportSupplementalProps> = ({
    reportTypeCode,
    reportNumber,
    primaryCTA,
    reportDAC,
    reportDTL,
    reportDTLP,
    reportDTTL,
    i18n,
}) => {
    // For the email to friend link curate the email subject and body for the "mailto" href
    const mailSubject: string = i18n['gia_www_i18n_emailsubject'] ?? 'GIA.edu: View GIA Report';
    const mailBody: string = i18n['gia_www_i18n_emailbody'] ?? 'Take a look at this GIA report via Report Check:';
    const emailMailToHref: string = `mailto:?Subject=${mailSubject}: ${reportNumber}&body=${mailBody}: ${window.location.href}`;

    return (
        <section className='report-supplemental text-center text-md-right'>
            {primaryCTA && (
                <a href={primaryCTA.toString()} className='btn btn-primary mb-2' target='_blank' rel='noreferrer'>
                    {reportTypeCode === 'DER'
                        ? i18n['gia_www_i18n_view_ereport']
                        : i18n['gia_www_i18n_download_report_pdf']}
                </a>
            )}
            {/* Report Access Card */}
            {reportDAC && (
                <p className='mb-0'>
                    <a
                        className='d-inline-flex small position-relative'
                        data-icon='download'
                        href={reportDAC}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <span className='label'>{i18n['gia_www_i18n_get_report_access_card']}</span>
                    </a>
                </p>
            )}
            {/* Diamond Type Letter (PDF) */}
            {reportDTL && (
                <p className='mb-0'>
                    <a
                        className='d-inline-flex small position-relative'
                        data-icon='download'
                        href={reportDTL}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <span className='label'>{i18n['gia_www_i18n_diamond_type_letter']}</span>
                    </a>
                </p>
            )}
            {/* Diamond Type Letter with Photo (PDF) */}
            {reportDTLP && (
                <p className='mb-0'>
                    <a
                        className='d-inline-flex small position-relative'
                        data-icon='download'
                        href={reportDTLP}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <span className='label'>{i18n['gia_www_i18n_diamond_type_letter_with_photo']}</span>
                    </a>
                </p>
            )}
            {/* Diamond Color Treatment Letter (PDF) */}
            {reportDTTL && (
                <p className='mb-0'>
                    <a
                        className='d-inline-flex small position-relative'
                        data-icon='download'
                        href={reportDTTL}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <span className='label'>{i18n['gia_www_i18n_diamond_color_treatment_letter']}</span>
                    </a>
                </p>
            )}
            {/* Email Report */}
            <p className='mb-0'>
                <a className='d-inline-flex small position-relative' href={emailMailToHref} data-icon='email'>
                    <span className='label'>{i18n['gia_www_i18n_emailreport']}</span>
                </a>
            </p>
        </section>
    );
};

export type { TReportSupplementalProps };
export default ReportSupplemental;
