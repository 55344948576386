import React from 'react';
import { TGSTPropertyLabels } from '@@config/report';

type TReportBasicInfoProps = {
    readonly reportNumber: string;
    readonly reportDateFormatted: string;
    readonly i18n: TGSTPropertyLabels;
};

const ReportBasicInfo: React.FC<TReportBasicInfoProps> = ({ reportNumber, reportDateFormatted, i18n }) => {
    /** Translations pulled from the i18n JSON object that is received from GIA.edu endpoint */
    const gia_www_i18n_giareportnum: string = i18n['gia_www_i18n_giareportnum'] ?? 'GIA Report Number';
    const gia_www_i18n_dateofissue: string = i18n['gia_www_i18n_dateofissue'] ?? 'Date of Issue';

    return (
        <div className='report-basic-info d-flex mb-2 mb-lg-0'>
            <div className='d-flex flex-column'>
                <h3 className='m-0 p-0 uppercase'>{gia_www_i18n_giareportnum}</h3>
                <p id='REPORT_NO' className='mt-1 mt-md-2'>
                    {reportNumber}
                </p>
            </div>
            <div className='d-flex flex-column'>
                <h3 className='m-0 p-0 uppercase text-right text-md-left'>{gia_www_i18n_dateofissue}</h3>
                <p id='REPORT_DT' className='mt-1 mt-md-2 text-right text-md-left'>
                    {reportDateFormatted}
                </p>
            </div>
        </div>
    );
};

export default ReportBasicInfo;
export type { TReportBasicInfoProps };
