import React from 'react';

type TInscriptionImageProps = {
    imageSource: string;
    altText: string;
};

const InscriptionImage: React.FC<TInscriptionImageProps> = ({ imageSource, altText }): React.JSX.Element => {
    return <img className='inscription-graphic' src={imageSource} alt={altText} />;
};

export default InscriptionImage;
