import React from 'react';
import parse from 'html-react-parser';
import { Accordion } from 'react-bootstrap';
import { isImage, TSupplementalContent } from '@@config/wcs';

type TProvenanceAccordionProps = {
    tracrId?: string | undefined;
    provenanceName?: string | undefined;
    supplementalContent: TSupplementalContent[];
};

const ProvenanceAccordion: React.FC<TProvenanceAccordionProps> = ({
    tracrId = '',
    provenanceName = '',
    supplementalContent,
}) => {
    return (
        <>
            <p className='title'>More Information Listed in the Report</p>
            <Accordion className='mr-3'>
                {supplementalContent.map((content, index) => (
                    <Accordion.Item eventKey={`${index}`} key={`${content?.['ASSET_ID'].CID}`}>
                        <Accordion.Header as='h5' className='w-100 d-flex justify-content-between'>
                            <span>{parse(content.TITLE)}</span>
                            <span></span>
                        </Accordion.Header>

                        <Accordion.Body className='px-5 py-3'>
                            <dl className='d-flex align-items-baseline font-weight-bold w-100'>
                                <dt>Tracr ID</dt>
                                <hr className='dotted-separator mx-2' />
                                <dd id='TRACR_ID' data-provenance-name={provenanceName}>
                                    {tracrId}
                                </dd>
                            </dl>
                            {content?.['BODY_JSON'].map(item => {
                                return Object.entries(item).map(([key, value]) => {
                                    if (key === 'BODY' && Array.isArray(value)) {
                                        const [
                                            countryList = '',
                                            clickHereStatic = '',
                                            aboutTracr = '',
                                            disclosure = '',
                                        ] = value;

                                        const tracrUrl = encodeURIComponent(`https://search.tracr.com/${tracrId}`);
                                        const redirectUrl = `https://www.gia.edu/tracr-redirect?redirectUrl=${tracrUrl}`;

                                        const clickHereDynamic = clickHereStatic.replace(
                                            /<a href=['"]#TRACR_URL['"]>/,
                                            `<a href="${redirectUrl}" target="_blank" class="external-link-blue">`
                                        );

                                        return (
                                            <>
                                                <dl className='d-flex align-items-baseline font-weight-bold w-100'>
                                                    <dt>Country of Origin</dt>
                                                    <hr className='dotted-separator mx-2' />
                                                    <dd>{parse(countryList)}</dd>
                                                </dl>
                                                <p>{parse(clickHereDynamic)}</p>
                                                <p>{parse(aboutTracr)}</p>
                                                <p>{parse(disclosure)}</p>
                                            </>
                                        );
                                    }

                                    if (key === 'IMAGE' && isImage(value)) {
                                        return (
                                            <div className='d-flex justify-content-center' key={key}>
                                                <img
                                                    className='img-fluid mb-2'
                                                    src={value['SRC']}
                                                    alt={value['ALT_TEXT']}
                                                    style={{ maxWidth: 175, opacity: 0.65 }}
                                                />
                                            </div>
                                        );
                                    }

                                    return null;
                                });
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    );
};

export type { TProvenanceAccordionProps };
export default ProvenanceAccordion;
