import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import AGSGemAttributeCard from '@@components/AGSGemAttributeCard/AGSGemAttributeCard';
import AGSReportDataGroup from '@@components/AGSReportDataGroup/AGSReportDataGroup';
import { TLocale } from '@@config/locale/';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getAGSJSONContentURL } from '@@utils/utils';
import Fancybox from '@@components/Fancybox/Fancybox';

const fetchAGSJSONContent = async (locale: TLocale) => {
    const url: URL = getAGSJSONContentURL(locale);
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });
    const response = await fetch(request);

    if (!response.ok) {
        throw new Error('Failed to fetch AGS JSON content');
    }

    return response.json();
};

type TAGSReportProps = {
    readonly REPORT_NO: string;
    readonly REPORT_DT_FORMATTED: string;
    readonly AGS_SHAPE: string;
    readonly AGS_MEASUREMENTS: string;
    readonly AGS_CARAT_WEIGHT: string;
    readonly AGS_LIGHT_PERFORMANCE_GRADE: string;
    readonly AGS_BRIGHTNESS: string;
    readonly AGS_FIRE: string;
    readonly AGS_CONTRAST: string;
    readonly AGS_PDF_URL: string;
    readonly AGS_LIGHTIMG: string;
};

const AGSReport: React.FC<TAGSReportProps> = ({
    REPORT_NO,
    REPORT_DT_FORMATTED,
    AGS_SHAPE,
    AGS_MEASUREMENTS,
    AGS_CARAT_WEIGHT,
    AGS_LIGHT_PERFORMANCE_GRADE,
    AGS_BRIGHTNESS,
    AGS_FIRE,
    AGS_CONTRAST,
    AGS_PDF_URL,
    AGS_LIGHTIMG,
}) => {
    const { locale }: TLocaleContext = useContext(LocaleContext);

    const {
        data: content,
        isPending,
        error,
    } = useQuery({
        queryKey: ['AGSJSONContent', locale],
        queryFn: () => fetchAGSJSONContent(locale),
        staleTime: 60 * 60 * 1000, // 60 minutes
    });

    if (error) {
        console.error(error);
        return <div>Error loading AGS content</div>;
    }

    if (content && !isPending) {
        const { data: labels } = content;
        const { LIGHT_PERFORMANCE_SCALE_IMAGE, ASET_MAP_LEGEND_IMAGE } = content.data;

        const gemAttributes = [
            {
                id: 'brightness',
                title: labels['BRIGHTNESS'] ?? '',
                description: labels['BRIGHTNESS_DESC'] ?? '',
            },
            {
                id: 'fire',
                title: labels['FIRE'] ?? '',
                description: labels['FIRE_DESC'] ?? '',
            },
            {
                id: 'contrast',
                title: labels['CONTRAST'] ?? '',
                description: labels['CONTRAST_DESC'] ?? '',
            },
        ];

        return (
            <div id='ags-report' className='container-fluid'>
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <h3 id='ags-header' className='text-center'>
                            {parse(labels?.['TITLE'] ?? '')}
                        </h3>
                        <p id='ags-sub-header' className='text-muted text-left'>
                            {parse(labels?.['SUBTITLE'] ?? '')}
                        </p>
                        <div className='d-flex justify-content-center align-items-center'>
                            {AGS_PDF_URL && (
                                <a id='ags-download-pdf' href={AGS_PDF_URL} target='_blank' rel='noreferrer'>
                                    <span>{parse(labels?.['DOWNLOAD_PDF'] ?? '')}</span>
                                </a>
                            )}
                        </div>
                    </div>
                </div>

                <div className='row no-gutters'>
                    <section className='ags-report-title-bar'>
                        <div className='box-gray'></div>
                        <h3 className='title'>{parse(labels?.['GRADING_DETAILS'] ?? '')}</h3>
                    </section>
                    <div className='col-sm-12 col-md-11 mx-auto'>
                        <dl className='report-data'>
                            <AGSReportDataGroup
                                label={labels?.['DATE'] ?? ''}
                                value={REPORT_DT_FORMATTED}
                                tooltip={labels?.['DATE_TOOLTIP'] ?? ''}
                            />

                            <AGSReportDataGroup
                                label={labels?.['REPORT_NUMBER'] ?? ''}
                                value={REPORT_NO}
                                tooltip={labels?.['REPORT_NUMBER_TOOLTIP'] ?? ''}
                            />

                            {AGS_SHAPE && (
                                <AGSReportDataGroup
                                    label={labels?.['SHAPE_AND_STYLE'] ?? ''}
                                    value={AGS_SHAPE}
                                    tooltip={labels?.['SHAPE_AND_STYLE_TOOLTIP'] ?? ''}
                                />
                            )}

                            {AGS_MEASUREMENTS && (
                                <AGSReportDataGroup
                                    label={labels?.['MEASUREMENTS'] ?? ''}
                                    value={AGS_MEASUREMENTS}
                                    tooltip={labels?.['MEASUREMENTS_TOOLTIP'] ?? ''}
                                />
                            )}

                            <br />

                            {AGS_LIGHT_PERFORMANCE_GRADE && (
                                <AGSReportDataGroup
                                    label={labels?.['LIGHT_PERFORMANCE_GRADE'] ?? ''}
                                    value={AGS_LIGHT_PERFORMANCE_GRADE}
                                    tooltip={labels?.['LIGHT_PERFORMANCE_GRADE_TOOLTIP'] ?? ''}
                                />
                            )}
                            {AGS_CARAT_WEIGHT && (
                                <AGSReportDataGroup
                                    label={labels?.['CARAT_WEIGHT'] ?? ''}
                                    value={AGS_CARAT_WEIGHT}
                                    tooltip={labels?.['CARAT_WEIGHT_TOOLTIP'] ?? ''}
                                />
                            )}
                        </dl>
                    </div>
                </div>

                {AGS_LIGHTIMG && (
                    <div className='row no-gutters'>
                        <section className='ags-report-title-bar'>
                            <div className='box-gray'></div>
                            <h3 className='title'>{parse(labels?.['ASET_MAP'] ?? '')}</h3>
                        </section>
                        <div className='col-sm-12 col-md-10 mx-auto'>
                            <figure id='aset-map'>
                                <img src={AGS_LIGHTIMG} alt='...' />
                                <img src={ASET_MAP_LEGEND_IMAGE['SRC']} alt={labels?.['ASET_MAP_LEGEND_IMAGE'] ?? ''} />
                                <figcaption>{parse(labels?.['ASET_MAP_CAPTION'] ?? '')}</figcaption>
                            </figure>
                        </div>
                    </div>
                )}

                <div className='row no-gutters'>
                    <section className='ags-report-title-bar'>
                        <div className='box-gray'></div>
                        <h3 className='title'>{parse(labels?.['LIGHT_PERFORMANCE_DETAILS'] ?? '')}</h3>
                    </section>
                    <div className='col-sm-12 col-md-11 mx-auto'>
                        <dl className='report-data'>
                            {AGS_LIGHT_PERFORMANCE_GRADE && (
                                <AGSReportDataGroup
                                    label={labels?.['LIGHT_PERFORMANCE_GRADE'] ?? ''}
                                    value={AGS_LIGHT_PERFORMANCE_GRADE}
                                    tooltip={labels?.['LIGHT_PERFORMANCE_GRADE_TOOLTIP'] ?? ''}
                                />
                            )}
                            {AGS_BRIGHTNESS && (
                                <div className='pl-3'>
                                    <AGSReportDataGroup
                                        label={labels?.['BRIGHTNESS'] ?? ''}
                                        value={AGS_BRIGHTNESS}
                                        tooltip={labels?.['BRIGHTNESS_TOOLTIP'] ?? ''}
                                    />
                                </div>
                            )}
                            {AGS_FIRE && (
                                <div className='pl-3'>
                                    <AGSReportDataGroup
                                        label={labels?.['FIRE'] ?? ''}
                                        value={AGS_FIRE}
                                        tooltip={labels?.['FIRE_TOOLTIP'] ?? ''}
                                    />
                                </div>
                            )}
                            {AGS_CONTRAST && (
                                <div className='pl-3'>
                                    <AGSReportDataGroup
                                        label={labels?.['CONTRAST'] ?? ''}
                                        value={AGS_CONTRAST}
                                        tooltip={labels?.['CONTRAST_TOOLTIP'] ?? ''}
                                    />
                                </div>
                            )}
                        </dl>
                    </div>
                    <div className='col-12 my-3'>
                        <p className='report-data-subhead'>{parse(labels?.['LIGHT_PERFORMANCE_SCALE'] ?? '')}</p>
                        <figure>
                            <Fancybox>
                                <a data-fancybox href={LIGHT_PERFORMANCE_SCALE_IMAGE['SRC']}>
                                    <img
                                        data-id={LIGHT_PERFORMANCE_SCALE_IMAGE?.['ASSET_ID']?.['CID']}
                                        src={LIGHT_PERFORMANCE_SCALE_IMAGE['SRC']}
                                        alt={LIGHT_PERFORMANCE_SCALE_IMAGE['ALT_TEXT']}
                                    />
                                </a>
                            </Fancybox>
                        </figure>
                    </div>
                    <div className='col-12'>
                        <p className='report-data-subhead'>{parse(labels?.['LIGHT_PERFORMANCE'] ?? '')}</p>
                        <p className='text-left text-muted'>{parse(labels?.['LIGHT_PERFORMANCE_SUBTITLE'] ?? '')}</p>
                    </div>
                    <div className='light-performance-legend'>
                        {gemAttributes.map(attr => (
                            <AGSGemAttributeCard key={attr.id} {...attr} />
                        ))}
                    </div>
                </div>

                <div className='disclaimer-container'>
                    <p className='disclaimer-header text-left'>{labels?.['IMPORTANT_LIMITATIONS']}</p>
                    <p className='disclaimer-copy text-muted text-left'>
                        {parse(labels?.['IMPORTANT_LIMITATIONS_COPY'])}
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className='row'>
            <div className='col'>
                <div className='skeleton h-100 mx-3' style={{ height: 'min(65vh, 35rem)' }}></div>
            </div>
        </div>
    );
};

export default AGSReport;
