import React from 'react';
import parse from 'html-react-parser';
import { Accordion } from 'react-bootstrap';
import { TSupplementalContent } from '@@config/wcs';

type TSVSAccordionProps = {
    svsName: string;
    supplementalContent: TSupplementalContent[];
};

const SVSAccordion: React.FC<TSVSAccordionProps> = ({ svsName, supplementalContent }) => {
    return (
        <>
            <p className='title'>More Information Listed in the Report</p>
            <Accordion className='mr-3'>
                {supplementalContent.map((content, index) => (
                    <Accordion.Item eventKey={`${index}`} key={`${content?.['ASSET_ID'].CID}`}>
                        <Accordion.Header as='h5' className='w-100 d-flex justify-content-between'>
                            <span>{parse(content.TITLE)}</span>
                            <span></span>
                        </Accordion.Header>

                        <Accordion.Body className='px-5 py-3'>
                            {content?.['BODY_JSON'].map(item => {
                                return Object.entries(item).map(([key, value]) => {
                                    if (key === 'TITLE' && typeof value === 'string') {
                                        return (
                                            <p key={`${key}`}>
                                                <span key={key} className='font-weight-bold mr-3'>
                                                    {parse(value)}
                                                </span>
                                                <span id='SVS_NAME'>{svsName}</span>
                                            </p>
                                        );
                                    }
                                    if (key === 'BODY' && Array.isArray(value)) {
                                        return value.map((paragraph, j) => (
                                            <p className='font-italic' key={`${key}-${j}`}>
                                                {parse(paragraph)}
                                            </p>
                                        ));
                                    }

                                    return null;
                                });
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>
    );
};

export type { TSVSAccordionProps };
export default SVSAccordion;
