import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import 'dayjs/locale/zh-cn';
import React, { useContext } from 'react';
import parse from 'html-react-parser';
import { Tab, Tabs } from 'react-bootstrap';
import { useLoaderData } from 'react-router';
import AdditionalInformation from '@@components/AdditionalInformation/AdditionalInformation';
import AGSReport from '@@components/AGSReport/AGSReport';
import DataError, { TErrorCode } from '@@components/DataError/DataError';
import Fancybox from '@@components/Fancybox/Fancybox';
import GradingReport from '@@components/GradingReport/GradingReport';
import ImageLoader from '@@components/ImageLoader/ImageLoader';
import JewelryGradingReport from '@@components/JewelryGradingReport/JewelryGradingReport';
import LabGrownBanner from '@@components/LabGrownBanner/LabGrownBanner';
import ProvenanceAccordion from '@@components/ProvenanceAccordion/ProvenanceAccordion';
import ReportBasicInfo from '@@components/ReportBasicInfo/ReportBasicInfo';
import ReportSupplemental from '@@components/ReportSupplemental/ReportSupplemental';
import ReportSupplementals from '@@components/ReportSupplementals/ReportSupplementals';
import RightRail from '@@components/RightRail/RightRail';
import SupplementalsAccordion from '@@components/SupplementalsAccordion/SupplementalsAccordion';
import SVSAccordion from '@@components/SVSAccordion/SVSAccordion';
import { TJewelryReportData, TLabels, TReportData, TReportTypeCode } from '@@config/report';
import { TLocale } from '@@config/locale';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import {
    getAWSEnv,
    getElectronicReportURL,
    getLocaleDayJS,
    getReportCheckLandingURL,
    pushEvent,
} from '@@utils/utils.ts';

type LoaderData = {
    reportData: TReportData | TJewelryReportData;
    labels: TLabels;
    error?: { code: TErrorCode; message: string };
    locale: TLocale;
    reportno?: string;
};

const Results: React.FC = () => {
    const { locale }: TLocaleContext = useContext(LocaleContext);
    const loaderData = useLoaderData() as LoaderData;

    const { reportData, labels, error, reportno: requestedReportNo } = loaderData;

    if (error?.code === '401') {
        window.location.href = getReportCheckLandingURL(requestedReportNo, locale);
        return null;
    }

    if (error) {
        return <DataError errorCode={error.code} fallbackMessage={error.message} />;
    }

    if (getAWSEnv() === 'prod') {
        pushEvent('reportType', reportData.REPORT_TYPE ?? '');
        pushEvent('reportDate', reportData.REPORT_DT ?? '');
    }

    // Standard fields present univerally across all report types
    const REPORT_NO = reportData.REPORT_NO;
    const REPORT_DT: Date = new Date(reportData.REPORT_DT);
    const REPORT_DT_FORMATTED = dayjs(REPORT_DT).locale(getLocaleDayJS(locale)).format('MMM. D, YYYY');
    const PDF_URL = reportData.PDF_URL;
    const REPORT_TYPE_CODE: TReportTypeCode = reportData.REPORT_TYPE_CODE;

    /**
     * The primary download URL or CTA is determined by the report type code and
     * whether or not a PDF is available which is dictated by truthiness of
     * the reports' PDF_URL
     */
    let primaryCTA: URL | undefined = undefined;

    // (Report) Type narrowing based on REPORT_TYPE_CODE
    const isJewelryReport = (data: TReportData | TJewelryReportData): data is TJewelryReportData =>
        data.REPORT_TYPE_CODE === 'JG' || data.REPORT_TYPE_CODE === 'JGC';

    if (isJewelryReport(reportData)) {
        if (PDF_URL) primaryCTA = new URL(PDF_URL);

        return (
            <main id='main-container' className='container-default my-4 mx-auto' style={{ minHeight: 'auto' }}>
                <section className='d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-start'>
                    <ReportBasicInfo
                        reportNumber={REPORT_NO}
                        reportDateFormatted={REPORT_DT_FORMATTED}
                        i18n={labels.legacy}
                    />

                    <ReportSupplementals
                        reportTypeCode={REPORT_TYPE_CODE}
                        reportNumber={REPORT_NO}
                        primaryCTA={primaryCTA}
                        labels={labels}
                    />
                </section>

                <hr className='dotted-double mt-sm-1 mt-md-3' />

                {/* Main Report Sleeve */}
                <JewelryGradingReport data={reportData} labels={labels} />

                <AdditionalInformation reportTypeCode={REPORT_TYPE_CODE} />
            </main>
        );
    } else {
        /** Default Grading Report for diamonds and other gemstones */
        const WEIGHT = reportData?.WEIGHT ?? '',
            CUT_GRADE = reportData?.CUT_GRADE ?? '';

        /**
         * The primary download URL is determined by the report type code and whether or not a PDF is available.
         */
        if (REPORT_TYPE_CODE === 'DER') {
            // For Diamond eReport we have to curate a custom URL based on the report number and weight.
            primaryCTA = getElectronicReportURL(REPORT_NO, WEIGHT);
        } else if (PDF_URL) {
            // Default for most reports is the PDF_URL.
            primaryCTA = new URL(PDF_URL);
        } else {
            primaryCTA = undefined;
        }

        // Boolean currently only for determining whether to display the subtitle "FOR NATURAL DIAMOND" below report type.
        const isNaturalDiamond = ['DD', 'CI', 'CD', 'FC', 'DER', 'DG', 'DOR', 'CDOR'].includes(REPORT_TYPE_CODE);
        // Boolean for whether to display the full-width Lab-Grown Banner above report info.
        const isLabGrown = ['LGDOSS', 'LGDG', 'LGCD', 'LGCI', 'LGDR'].includes(REPORT_TYPE_CODE);

        const showTabs: boolean = Boolean(
            reportData?.['IS_AGS'] === 'TRUE' ||
                reportData['COR_DATA']?.length ||
                reportData['GROWTH_METHOD_DATA']?.length ||
                reportData['SVS_DATA']?.length ||
                reportData['TREATMENTS_DATA']?.length ||
                (reportData['TRACR_DATA']?.length && reportData?.['TRACR_ID'])
        );

        return (
            <main id='main-container' className='container-default my-4 mx-auto' style={{ minHeight: 'auto' }}>
                <section className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-start'>
                    <ReportBasicInfo
                        reportNumber={REPORT_NO}
                        reportDateFormatted={REPORT_DT_FORMATTED}
                        i18n={labels.legacy}
                    />
                    <div className='d-none d-md-block'>
                        <ReportSupplemental
                            reportTypeCode={REPORT_TYPE_CODE}
                            reportNumber={REPORT_NO}
                            primaryCTA={primaryCTA}
                            reportDAC={reportData.DIGITAL_CARD ?? ''}
                            reportDTL={reportData.DTL_PDF_URL ?? ''}
                            reportDTLP={reportData.DTLP_PDF_URL ?? ''}
                            reportDTTL={reportData.DTTL_PDF_URL ?? ''}
                            i18n={labels.legacy}
                        />
                    </div>
                </section>
                <hr className='dotted-double mt-sm-1 mt-md-3' />
                <div className='row report-details-and-sidebar no-gutters'>
                    {isLabGrown && (
                        <div className='col-12'>
                            <LabGrownBanner reportTypeCode={REPORT_TYPE_CODE} i18n={labels.legacy} />
                        </div>
                    )}
                    <div className='col-sm-12 col-md-9'>
                        <div className='row mt-3 mb-5'>
                            <div className='col'>
                                <h1 id='REPORT_TYPE' className='text-left bg-none letter-spacing-normal pb-0 mb-0'>
                                    {parse(reportData?.['REPORT_TYPE'])}
                                </h1>
                                {isNaturalDiamond && (
                                    <span id='REPORT_TYPE_SUBTITLE' className='text-uppercase font-weight-bold'>
                                        FOR NATURAL DIAMOND
                                    </span>
                                )}
                            </div>
                            {reportData?.['DGTIMG'] && (
                                <div className='col-sm-12 col-md-3'>
                                    <div className='mr-0 mr-md-3'>
                                        <Fancybox>
                                            <a href={reportData['DGTIMG']} data-fancybox>
                                                <ImageLoader
                                                    className='img-fluid'
                                                    src={reportData['DGTIMG']}
                                                    minHeight={125}
                                                    alt='...'
                                                />
                                            </a>
                                        </Fancybox>
                                    </div>
                                </div>
                            )}
                        </div>

                        {!showTabs && <GradingReport data={reportData} i18n={labels.legacy} />}

                        {showTabs && (
                            <Tabs
                                defaultActiveKey='report-details'
                                className='supp-tabs d-flex justify-content-center text-uppercase my-3 border-0'
                            >
                                <Tab eventKey='report-details' title='Report Details'>
                                    <GradingReport data={reportData} i18n={labels.legacy} />
                                </Tab>

                                {reportData['COR_DATA'] && (
                                    <Tab eventKey='country-of-origin' title='Country of Origin'>
                                        <SupplementalsAccordion supplementalContent={reportData['COR_DATA']} />
                                    </Tab>
                                )}

                                {reportData?.['TREATMENTS_DATA'] && !reportData?.['GROWTH_METHOD'] && (
                                    <Tab eventKey='treatment' title='Treatment'>
                                        <SupplementalsAccordion supplementalContent={reportData['TREATMENTS_DATA']} />
                                    </Tab>
                                )}

                                {reportData?.['GROWTH_METHOD_DATA'] && (
                                    <Tab eventKey='growth-method' title='Growth Method'>
                                        <SupplementalsAccordion
                                            supplementalContent={reportData['GROWTH_METHOD_DATA']}
                                        />
                                    </Tab>
                                )}

                                {reportData?.['SVS_DATA'] && (
                                    <Tab
                                        eventKey='svs'
                                        title={parse(`<span>GIA<sup>®</sup> Source Verification Service</span>`)}
                                    >
                                        <SVSAccordion
                                            svsName={reportData?.['SVS_NAME'] ?? ''}
                                            supplementalContent={reportData['SVS_DATA']}
                                        />
                                    </Tab>
                                )}

                                {reportData?.['TRACR_DATA'] && (
                                    <Tab eventKey='provenance' title='Provenance'>
                                        <ProvenanceAccordion
                                            tracrId={reportData['TRACR_ID']}
                                            provenanceName={reportData?.['PROVENANCE_NAME']}
                                            supplementalContent={reportData['TRACR_DATA']}
                                        />
                                    </Tab>
                                )}

                                {reportData?.['IS_AGS'] === 'TRUE' && (
                                    <Tab eventKey='ags' title={parse(`AGS Ideal<sup>&reg;</sup> Report`)}>
                                        <AGSReport
                                            REPORT_DT_FORMATTED={REPORT_DT_FORMATTED}
                                            REPORT_NO={REPORT_NO}
                                            AGS_SHAPE={reportData.AGS_SHAPE ?? ''}
                                            AGS_MEASUREMENTS={reportData.AGS_MEASUREMENTS ?? ''}
                                            AGS_CARAT_WEIGHT={reportData.AGS_CARAT_WEIGHT ?? ''}
                                            AGS_LIGHT_PERFORMANCE_GRADE={reportData.AGS_LIGHT_PERFORMANCE_GRADE ?? ''}
                                            AGS_BRIGHTNESS={reportData.AGS_BRIGHTNESS ?? ''}
                                            AGS_FIRE={reportData.AGS_FIRE ?? ''}
                                            AGS_CONTRAST={reportData.AGS_CONTRAST ?? ''}
                                            AGS_PDF_URL={reportData.AGS_PDF_URL ?? ''}
                                            AGS_LIGHTIMG={reportData.AGS_LIGHTIMG ?? ''}
                                        />
                                    </Tab>
                                )}
                            </Tabs>
                        )}
                    </div>
                    <div className='w-100 d-sm-block d-md-none my-4'>
                        <ReportSupplemental
                            reportTypeCode={REPORT_TYPE_CODE}
                            reportNumber={REPORT_NO}
                            primaryCTA={primaryCTA ?? undefined}
                            reportDAC={reportData.DIGITAL_CARD ?? ''}
                            reportDTL={reportData.DTL_PDF_URL ?? ''}
                            reportDTLP={reportData.DTLP_PDF_URL ?? ''}
                            reportDTTL={reportData.DTTL_PDF_URL ?? ''}
                            i18n={labels.legacy}
                        />
                    </div>
                    <aside id='right-rail' className='col-sm-12 col-md-3'>
                        <RightRail reportTypeCode={REPORT_TYPE_CODE} cutGrade={CUT_GRADE} />
                    </aside>
                </div>
            </main>
        );
    }
};

export default Results;
