import {
    TAdditionalInformation,
    TAssetId,
    TAliasModule,
    TImage,
    TGallery,
    TRelated,
    TSupplementalContent,
} from './types';

/**
 *
 * @param {unknown} value - The object to validate
 * @returns {boolean}
 */
export function isImage(value: unknown): value is TImage {
    return typeof value === 'object' && value !== null && 'SRC' in value && 'ALT_TEXT' in value;
}

/**
 *
 * @param {unknown} value - The object to validate
 * @returns {boolean}
 */
export function isMedia(value: unknown): value is TGallery {
    return (
        typeof value === 'object' &&
        value !== null &&
        'ASSET_ID' in value &&
        'PRIMARY_IMAGE' in value &&
        'GALLERY_IMAGES' in value &&
        'GALLERY_CAPTION' in value
    );
}

export type { TAdditionalInformation, TAssetId, TAliasModule, TImage, TGallery, TRelated, TSupplementalContent };
