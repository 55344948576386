import React from 'react';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { CharacteristicsInclusions, TCharacteristicsInclusion } from '@@config/characteristicsInclusions';
import { TReportEvent } from '@@config/gtm';
import { TKeyToSymbol } from '@@components/KeyToSymbol/KeyToSymbol';
import { TLocale, LocaleConfigs } from '@@config/locale/';
import { TLabels, TJewelryReportData, TReportData, TReportDataPayload, TReportTypeCode } from '@@config/report';

/**
 * Extends the global Window interface to include the GTM data layer.
 * This enables TypeScript to recognize and type-check data layer operations.
 *
 * @global
 * @example
 * // Push a new report event to the data layer
 * window.dataLayer.push({
 *     event: 'report_view',
 *     reportType: 'STANDARD'
 * });
 */
declare global {
    interface Window {
        dataLayer: TReportEvent[];
    }
}

/**
 * The possible error codes returned by the the Results loader directly or else received via the RDWB API Response and
 * passed along to the Results component.
 *
 * @type
 */
export type TErrorCode = '100' | '200' | '300' | '400' | '401' | '500' | '600' | '700' | '800' | '900';

/**
 * Gets the endpoint URL for the AliasModules JSON content from Oracle WCS.
 * @function
 * @param {TLocale} locale - The locale to be used in the URL.
 * @param {string} metaTitle - The metatitle used to lookup the AliasModule content module in WCS.
 * @returns {URL}
 */
export function getAliasModulesJSONURL(locale: TLocale, metaTitle: string): URL {
    const baseURL: string = getBaseURL();
    const url = `${baseURL}/sites/Satellite?pagename=GIA/AliasModules/JSON&locale=${locale}&metatitle=${metaTitle}`;
    return new URL(url);
}

/**
 * Gets the endpoint URL for the AGS Report JSON content from Oracle WCS.
 *
 * @function
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getAGSJSONContentURL(locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const url = `${baseURL}/sites/Satellite?pagename=GIA/ReportCheck/AGS/JSON&locale=${locale}`;
    return new URL(url);
}

/**
 * Get the AWS environment (e.g. 'local', 'dev', 'uat', 'prod') based on the corresponding meta tag in the HTML.
 * @function
 * @returns {string} The AWS environment.
 */
export function getAWSEnv(): string {
    const metaTag = document.querySelector('meta[name="aws-env"]');
    if (metaTag) {
        return metaTag?.getAttribute('content') ?? '';
    }
    return '';
}

/**
 * Get the base URL from the 'base' tag in the document.
 * @function
 * @returns {string} The base URL or an empty string if not found.
 */
export function getBaseURL(): string {
    let baseURL = '';
    const awsEnv = getAWSEnv();
    switch (awsEnv) {
        case 'local':
            baseURL = 'https://wcs-mob.gia.edu';
            break;
        case 'dev':
            baseURL = 'https://wcs-mob.gia.edu';
            break;
        case 'uat':
            baseURL = 'https://wcs-prod.gia.edu';
            break;
        case 'prod':
            baseURL = 'https://www.gia.edu';
            break;
        default:
            baseURL = 'https://www.gia.edu';
            break;
    }
    return baseURL;
}

/**
 * Get the RDWB API Host via the corresponding meta tag in the HTML.
 * @function
 * @returns {string} The RDWB host.
 */
export function getRdwbApiHost(): string {
    const metaTag = document.querySelector('meta[name="rdwb-api-host"]');
    if (metaTag) {
        return metaTag?.getAttribute('content') ?? '';
    }
    return '';
}

/**
 * Retrieves the value of a cookie by its name.
 *
 * @function
 * @param {string} cookieName - The name of the cookie to retrieve.
 * @returns {Promise<string>} A Promise that resolves to the value of the cookie as a string.
 * If the cookie is not found, the Promise will resolve with an empty string.
 * @throws {Error} If there is an error while retrieving the cookie, an Error object will be thrown.
 */
export function getCookie(cookieName: string): Promise<string> {
    return new Promise(resolve => {
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');

        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i] || '';
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                resolve(cookie.substring(name.length, cookie.length));
                return; // Exit the function after resolving the Promise
            }
        }

        resolve(''); // Cookie not found, resolve with an empty string
    });
}

/**
 * Determines which Diamond Origin disclaimer is applicable based on report date, and a list of exception cases.
 *
 *
 * @param {string} reportNumber - The unique identifier for the diamond report
 * @param {Date} reportDate - The date when the report was issued
 * @param {string} oldDisclaimer - The legacy disclaimer text used for older reports
 * @param {string} currentDisclaimer - The current disclaimer text used for newer reports
 *
 * @returns {string} The appropriate disclaimer text based on the report number and date
 */
export function getDiamondOriginDisclaimer(
    reportNumber: string,
    reportDate: Date,
    oldDisclaimer: string,
    currentDisclaimer: string
): string {
    const DORExceptions: string[] = [
        '6207489194',
        '6204489200',
        '1206489210',
        '6204413374',
        '6204236353',
        '2201265284',
        '6204265314',
        '6203489265',
        '6204489269',
        '2201489270',
        '2205609645',
    ];

    const isLegacyDOR: boolean = reportDate < new Date('02/17/2021');

    return DORExceptions.includes(reportNumber) || !isLegacyDOR ? currentDisclaimer : oldDisclaimer;
}

/**
 * Gets the eReport URL by first removing any non-numeric characters from the stone weight and then constructing the URL.
 *
 * @param {string | number} reportNo
 * @param {string} weight
 * @returns {URL} The eReport URL for the given report number and stone weight.
 */
export function getElectronicReportURL(reportNo: string | number, weight: string): URL {
    const stoneWeight: string = weight.replace(/[^\d.-]/g, '');
    const eReportUrl: string =
        'https://myapps.gia.edu/ReportCheckPortal/getReportData.do?&reportno=' + reportNo + '&weight=' + stoneWeight;
    return new URL(eReportUrl);
}

/**
 * Gets the "Key to Symbols" images and and labels by parsing the appropriate data in the report
 *
 * @param {string} clarityCharacteristics
 * @param {string} keyToSymbols
 * @returns {TKeyToSymbol[]} The Key to Symbols imageName/path and label
 */
export function getKeysToSymbols(clarityCharacteristics: string, keyToSymbols: string): TKeyToSymbol[] {
    const chars = clarityCharacteristics?.split(', ') ?? [];
    const ktsImages = keyToSymbols?.split(', ') ?? [];

    return chars.map((char: string, index: number) => {
        const imageName = ktsImages[index]
            ? (ktsImages[index].replace(/\s/g, '') ?? '')
            : CharacteristicsInclusions[char as TCharacteristicsInclusion];

        return {
            imageName: imageName,
            label: char as TCharacteristicsInclusion,
        };
    });
}

/**
 * Gets the endpoint URL for the GIA Report Check Related JSON content from Oracle WCS.
 *
 * @function
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getReportCheckRelatedJSONURL(locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const url = `${baseURL}/sites/Satellite?pagename=GIA/ReportCheck/RelatedJSON&locale=${locale}`;
    return new URL(url);
}

/**
 * Gets the endpoint URL for the GIA Report Check Related JSON content from Oracle WCS.
 *
 * @function
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getReportCheckErrorMessageURL(errorCode: TErrorCode, locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const url = `${baseURL}/sites/Satellite?pagename=GIA/ReportCheck/MessageJSON&reportcheckmessage=${errorCode}&locale=${locale}`;
    return new URL(url);
}

/**
 * Gets the endpoint URL for the GIA Report Check Landing main content from Oracle WCS.
 *
 * @function
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getReportCheckLandingContentURL(locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const localePath: string = LocaleConfigs[locale].path;
    const url = `${baseURL}${
        baseURL.includes('wcs') ? '/sites/gia/' : ''
    }${localePath}/report-check-main?headless=true&token=false`;
    return new URL(url);
}

/**
 * Generates the URL for the report check landing page.
 *
 * @function
 * @param {string} reportNo - The report number to be checked.
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {string} The formatted URL path for the report check landing page.
 */
export function getReportCheckLandingURL(reportNo: string = '', locale: TLocale): string {
    const baseUrl = '/report-check-landing';
    const params = new URLSearchParams();

    if (reportNo) {
        params.append('reportno', reportNo);
    }
    params.append('locale', locale);

    const queryString = params.toString();
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
}

/**
 * Gets the endpoint URL for the GIA Report Check Footer content from Oracle WCS.
 *
 * @function
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getReportCheckFooterContentURL(locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const url = `${baseURL}/sites/Satellite?pagename=GIA/ReportCheck/Foot3r&locale=${locale}`;
    return new URL(url);
}

/**
 * Gets the endpoint URL for the GIA Report Check Header content from Oracle WCS.
 *
 * @function
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getReportCheckHeaderContentURL(locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const url = `${baseURL}/sites/Satellite?pagename=GIA/ReportCheck/Header&locale=${locale}`;
    return new URL(url);
}

/**
 * Gets the endpoint URL for the GIA Report Check JSON content from Oracle WCS.
 *
 * @function
 * @param {TReportTypeCode} reportType - The type of report to be used in the URL.
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getReportCheckJSONURL(reportType: TReportTypeCode, locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const url = `${baseURL}/sites/Satellite?pagename=GIA/ReportCheckJSON&reporttype=${reportType}&locale=${locale}&properties=false&version=2`;
    return new URL(url);
}

/**
 * Gets the endpoint URL for the GIA Report Check Right Rail content from Oracle WCS.
 *
 * @function
 * @param {TReportTypeCode} reportType - The type of report to be used in the URL.
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getReportCheckRightRailContentURL(reportType: TReportTypeCode, locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const url = `${baseURL}/sites/Satellite?pagename=GIA/ReportCheck/RightRail&c=Page&cid=1495313612135&locale=${locale}&reporttype=${reportType}`;
    return new URL(url);
}

/**
 * Converts a locale string to the corresponding Day.js language code.
 *
 * @function
 * @param {TLocale} locale - The locale to convert to a Day.js language code.
 * @returns {string} The Day.js language code for the given locale.
 *
 */
export function getLocaleDayJS(locale: TLocale): string {
    const localeMap: Record<TLocale, string> = {
        en_US: 'en',
        zh_CN: 'zh-cn',
        ja_JP: 'ja',
    };

    return localeMap[locale] ?? 'en';
}

/**
 * Gets the value of a URL parameter by name.
 *
 * @param {string} name - The name of the parameter to retrieve.
 * @returns {string | null} The value of the parameter if found, or `null` if not found.
 */
export function getParameterByName(name: string): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

/**
 * Generates a Tracr URL for a given Tracr ID.
 *
 * @param {string} tracrId - The Tracr ID to create the URL for.
 * Note: The end-user will be navigated to the external Tracr website via the GIA redirect page.
 * @returns {string} The generated Tracr URL.
 */
export function getTracrRedirectURL(tracrId: string): string {
    return `https://www.gia.edu/tracr-redirect?redirectUrl=https://search.tracr.com/${tracrId}`;
}

/**
 * Checks if the URL query string contains a parameter 'qr' with a value of 'true'.
 * @param {string} urlQueryString - The URL query string containing parameters.
 * @returns {boolean} True if the 'qr' parameter equals 'true', false otherwise.
 */
export function isQrParameterTrue(urlQueryString: string = ''): boolean {
    // Use URLSearchParams to parse the query string
    const params: URLSearchParams = new URLSearchParams(urlQueryString);
    // Check if the 'qr' parameter exists and its value is 'true'
    return params.has('qr') && params.get('qr') === 'true';
}

/**
 * Merges the i18n labels and tooltips received via a WebCenter Sites powered JSON endpoint with report data.
 * @param {object} json
 * @param {object} data
 * @returns {object} The merged data object.
 */
export function mergeData(json: any, data: any): any {
    const mergedData: any = { ...json };

    mergedData['LABEL_CATEGORIES'] = json['LABEL_CATEGORIES']
        .filter((category: any) => {
            return category['ITEMS'].some((item: any) => {
                const key: string = Object.keys(item)[0] ?? '';
                const value = data[key];

                return Boolean(value);
            });
        })
        .map((category: any) => {
            const mergedCategory: any = { ...category };

            mergedCategory['ITEMS'] = category['ITEMS']
                .filter((item: any) => {
                    const key: string = Object.keys(item)[0] ?? '';
                    const value = data[key];

                    return Boolean(value);
                })
                .map((item: any) => {
                    const key: string = Object.keys(item)[0] ?? '';
                    const value = data[key];

                    if (value) {
                        return {
                            [key]: {
                                ...item[key],
                                VALUE: value,
                            },
                        };
                    }

                    return item;
                });

            return mergedCategory;
        });

    return mergedData;
}

/**
 * Parses HTML content and returns it as Preact elements.
 *
 * This function wraps the html-react-parser library, providing default options
 * for use with Preact. It allows parsing HTML strings into Preact components.
 *
 * @param {string} content - The HTML string to be parsed.
 * @param {HTMLReactParserOptions} [options={}] - Additional options to pass to the parser.
 *        These will be merged with the default options.
 * @returns {string|JSX.Element|JSX.Element[]} The parsed content as a string,
 *          Preact element, or array of Preact elements.
 *
 * @example
 * const htmlString = '<p>Hello, <strong>world!</strong></p>';
 * const parsedContent = parseHTML(htmlString);
 * // Use in JSX: <div>{parsedContent}</div>
 */
export function parseHTML(
    content: string,
    options: HTMLReactParserOptions = {}
): string | React.JSX.Element | React.JSX.Element[] {
    const defaultOptions: HTMLReactParserOptions = {
        ...options,
    };
    return parse(content, defaultOptions);
}

/**
 * Parses the locale from the given query string.
 * If the locale in the URL params is undefined or invalid returns ''.
 *
 * @function
 * @param {string} urlQueryString - The query string containing URL parameters.
 * @returns {string} The locale (e.g. 'en_US').
 */
export function parseLocale(urlQueryString: string = ''): string {
    const urlParams: URLSearchParams = new URLSearchParams(urlQueryString);
    const locale: string = urlParams.get('locale') || '';

    return locale;
}

/**
 * Parses the report number from the given URL query string.
 * If the report number is not found in the URL params, it returns an empty string.
 *
 * @function
 * @param {string} urlQueryString - The URL query string containing parameters.
 * @returns {string} The report number, or an empty string if not found.
 */
export function parseReportNumber(urlQueryString: string = ''): string {
    const urlParams: URLSearchParams = new URLSearchParams(urlQueryString);
    const reportno: string = urlParams.get('reportno') || '';

    return reportno;
}

/**
 * Parses the given URL query parameters and saves them individually by their parameter names in the browser local storage
 *
 * @function
 * @param {string} search - window.location.search
 * @returns {void} This function does not return any value.
 * @example
 * // If the URL is http://example.com/?name=Jonathan&age=40&location=New%20York
 * // The function will save the following key-value pairs in the local storage:
 * // Key: "name", Value: "Jonathan"
 * // Key: "age", Value: "40"
 * // Key: "location", Value: "New York"
 */
export function queryParamsToLocalStorage(search: string): void {
    // Get the query string
    const queryString: string = search.slice(1);

    // Split the query string into individual parameters
    const queryParams: string[] = queryString.split('&');

    // Create an object to store the parsed parameters
    const parsedParams: Record<string, string> = {};

    // Iterate through the parameters and save them to the object
    queryParams.forEach(param => {
        const [key, value] = param.split('=');
        if (key && value) {
            parsedParams[key] = decodeURIComponent(value);
        }
    });

    // Save the parsed parameters to the local storage
    for (const key in parsedParams) {
        if (key in parsedParams && parsedParams[key] !== undefined) {
            localStorage.setItem(key, parsedParams[key] || '');
        }
    }
}

/**
 * Sets a cookie with the specified name, value, and expiration time.
 *
 * @function
 * @param {string} cookieName - The name of the cookie to be set.
 * @param {string} cookieValue - The value to be stored in the cookie.
 * @param {number} expirationDays - The number of days until the cookie should expire.
 * @returns {void} This function does not return any value.
 *
 * @example
 * // Set a cookie named 'username' with value 'PeterPan' that expires in 7 days.
 * setCookie('username', 'PeterPan', 7);
 */
export function setCookie(cookieName: string, cookieValue: string, expirationDays: number): void {
    const date: Date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expires: string = 'expires=' + date.toUTCString();

    // Set the cookie with the provided name, value, and expiration time.
    document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
}

/**
 * Updates the Google Tag Manager (GTM) dataLayer with a specific metric key and value.
 *
 * @param {string} metricKey - The metric key to update in the dataLayer.
 * @param {string} metricValue - The metric value to update in the dataLayer.
 * @see {@link https://developers.google.com/tag-platform/tag-manager/datalayer}
 * @returns {void}
 */
export function pushEvent(metricKey: string, metricValue: string | TReportTypeCode): void {
    // Create an object with the dynamic metric key-value pair
    const event: TReportEvent = {
        event: 'reportEvent',
        timestamp: Date.now(),
    };

    if (metricKey === 'reportType') {
        event.reportType = metricValue as TReportTypeCode;
    }

    if (metricKey === 'reportDate') {
        event.reportDate = metricValue;
    }

    // Check if GTM (Google Tag Manager) is defined
    window.dataLayer = window.dataLayer || [];
    // Push the metric data to the dataLayer
    window.dataLayer.push(event);
}

/**
 * @function
 * @param {string} locale - The locale to be used within switch to set the UserWay widget language
 * @returns {void} This function does not return any value.
 */
export function updateUserWayWidgetLanguage(locale: string): void {
    let userWayLanguageCode: string = 'en-US';
    /* Supported languages can be found here: https://userway.org/docs/#widget-settings */
    switch (locale) {
        case 'ja_JP':
            userWayLanguageCode = 'ja';
            break;
        case 'zh_CN':
            userWayLanguageCode = 'zh';
            break;
        default:
            userWayLanguageCode = 'en-US';
    }

    /* Change widget language once it has initialized */
    document.addEventListener('userway:init_completed', function (event: Event) {
        /* @ts-expect-error: userway types unavailable */
        const instance = event.detail.userWayInstance;
        if (instance) instance.changeWidgetLanguage(userWayLanguageCode);
    });
}

/**
 * Sanitizes the report number.
 *
 * @function
 * @private
 * @param {string|number} reportNumber - The report number to sanitize.
 * @returns {string} The sanitized report number.
 */
export function sanitizeReportNumber(reportNumber: string | number): string {
    const maxLn = 15;
    // Convert to string (in case receiving number)
    let sanitized: string = reportNumber.toString();
    // Remove any non-alphanumeric characters, enforce max length, and capitalize the string (for JG reports)
    sanitized = sanitized
        .replace(/[^a-zA-Z0-9]/g, '')
        .trim()
        .slice(0, maxLn)
        .toUpperCase();

    return sanitized;
}

/**
 * Parses an API response by separating the labels from the report data.
 * This function handles both standard reports and jewelry-specific reports
 * through its generic type parameter.
 *
 * @template T - The specific report type, which must be either a standard
 * report (TReportData) or a jewelry report (TJewelryReportData)
 *
 * @param {TReportDataPayload} response - The raw API response containing
 * both the report data and associated labels
 *
 * @returns {Object} An object containing:
 *  - reportData: The parsed report data, typed as T
 *  - labels: The separated labels from the response
 */
export function parseReportResponse<T extends TReportData | TJewelryReportData>(
    response: TReportDataPayload
): {
    reportData: T;
    labels: TLabels;
} {
    const { labels, ...reportData } = response;
    return { reportData: reportData as T, labels };
}
